import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../../../components/layout"
import ChainInfo from "../../../../views/chain-info"
import { Seo } from "../../../../components/seo"
import {
  exportToCsv,
  getBanner,
  reduceValue,
} from "../../../../components/utils"
import axios from "axios"
import * as mainStyles from "../../../../styles/index.module.scss"
import Spinner from "../../../../components/spinner"
import { fromBase64 } from "@cosmjs/encoding"
import { defaultRegistryTypes } from "@cosmjs/stargate"
import { Registry, decodeTxRaw } from "@cosmjs/proto-signing"
import Code from "../../../../components/Code"
import { BsDownload } from "react-icons/bs"

export const Head = ({
  location,
  data: { testChainsJson: chain, allFile: files },
}) => (
  <Seo
    title={`${chain.name} Testnet transactions`}
    description={chain.about}
    pathname={location.pathname}
    image={getBanner(chain.key, files.edges)}
  />
)

const defaultRpc = "https://uptick-testnet-archive-rpc.brocha.in"
const defaultExplorer = "https://testnet-explorer.brocha.in/uptick"

const Chain = props => {
  const { testChainsJson, allFile } = props.data

  const [fromBlock, setFromBlock] = React.useState(1)
  const [toBlock, setToBlock] = React.useState(1)
  const [statusMessage, setStatusMessage] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false)
  const [transactions, setTransactions] = React.useState([])
  const [rpcAddress, setRpcAddress] = React.useState(defaultRpc)
  const [explorerAddress, setExplorerAddress] = React.useState(defaultExplorer)

  const doLookup = async () => {
    setIsLoading(true)
    setStatusMessage("")
    const registry = new Registry(defaultRegistryTypes)
    const transactionsTmp = []
    let blocksProcessed = 0

    try {
      const statusResponse = await fetch(`${rpcAddress}/status`)
      const statusJson = await statusResponse.json()
      const earliestHeight = parseInt(
        statusJson.result.sync_info.earliest_block_height
      )
      const latestHeight = parseInt(
        statusJson.result.sync_info.latest_block_height
      )

      if (toBlock <= fromBlock) {
        setStatusMessage(
          `ERROR: The "from height" must be lower than the "to height".`
        )
        return
      } else if (fromBlock < earliestHeight) {
        setStatusMessage(
          `ERROR: The "from height" is not available on the node. ${fromBlock} is lower than ${earliestHeight}.`
        )
        return
      } else if (toBlock > latestHeight) {
        setStatusMessage(
          `ERROR: The "to height" is not available on the node. ${toBlock} is higher than ${latestHeight}.`
        )
        return
      }

      for (let i = fromBlock; i <= toBlock; i++) {
        setStatusMessage(`Checking block ${i}...`)
        const blockResponse = await fetch(`${rpcAddress}/block?height=${i}`)
        const blockJson = await blockResponse.json()
        const transactions = blockJson.result.block.data.txs
        if (transactions.length > 0) {
          setStatusMessage(`Getting transactions from block ${i}...`)
          const txsResponse = await fetch(
            `${rpcAddress}/tx_search?query="tx.height=${i}"`
          )
          const txsJson = await txsResponse.json()

          for (let tx of txsJson.result.txs) {
            // const events = tx.tx_result.events.filter(
            //   e =>
            //     e.type === "message" &&
            //     String.fromCharCode(...fromBase64(e.attributes[0].key)) ===
            //       "action"
            // )
            // console.log(events)
            const decoded = decodeTxRaw(fromBase64(tx.tx))
            for (const message of decoded.body.messages) {
              let decodedMsg = {}
              try {
                decodedMsg = registry.decode(message)
              } catch (e) {}
              decodedMsg.type = message.typeUrl
              decodedMsg.block = i
              decodedMsg.tx = tx.hash
              transactionsTmp.push(decodedMsg)
              // console.log(decodedMsg)
            }
          }
          setTransactions(transactionsTmp)
        }
        blocksProcessed++
        // if (blocksProcessed % 10 === 0) {
        //   setStatusMessage(`Processed ${blocksProcessed} blocks...`)
        // }
      }
      setStatusMessage(
        `Done after processing ${blocksProcessed} blocks. See the results below.`
      )
      setTransactions(transactionsTmp)
    } catch (e) {
      setStatusMessage(
        `ERROR: Something went wrong at block height ${
          parseInt(fromBlock) + blocksProcessed
        }: "${e.message}"`
      )
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const renderInformation = transaction => {
    switch (transaction.type) {
      case "/cosmos.bank.v1beta1.MsgSend": {
        return (
          <React.Fragment>
            Sender:{" "}
            <a
              href={`${explorerAddress}/account/${transaction.fromAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              <abbr title={transaction.fromAddress}>
                {transaction.fromAddress.substr(0, 9)}...
                {transaction.fromAddress.substr(-5)}
              </abbr>
            </a>
            <br />
            Recipient:{" "}
            <a
              href={`${explorerAddress}/account/${transaction.toAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              <abbr title={transaction.toAddress}>
                {transaction.toAddress.substr(0, 9)}...
                {transaction.toAddress.substr(-5)}
              </abbr>
            </a>
            <br />
            Amount:{" "}
            {transaction.amount
              .map(
                a =>
                  `${reduceValue(a.amount, a.denom)} ${a.denom
                    .toUpperCase()
                    .substr(1)}`
              )
              .join(", ")}
          </React.Fragment>
        )
      }
      case "/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward": {
        return (
          <React.Fragment>
            Delegator:{" "}
            <a
              href={`${explorerAddress}/account/${transaction.delegatorAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              <abbr title={transaction.delegatorAddress}>
                {transaction.delegatorAddress.substr(0, 9)}...
                {transaction.delegatorAddress.substr(-5)}
              </abbr>
            </a>
            <br />
            Validator:{" "}
            <a
              href={`${explorerAddress}/staking/${transaction.validatorAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              <abbr title={transaction.validatorAddress}>
                {transaction.validatorAddress.substr(0, 9)}...
                {transaction.validatorAddress.substr(-5)}
              </abbr>
            </a>
          </React.Fragment>
        )
      }
      case "/cosmos.staking.v1beta1.MsgDelegate": {
        return (
          <React.Fragment>
            Delegator:{" "}
            <a
              href={`${explorerAddress}/account/${transaction.delegatorAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              <abbr title={transaction.delegatorAddress}>
                {transaction.delegatorAddress.substr(0, 9)}...
                {transaction.delegatorAddress.substr(-5)}
              </abbr>
            </a>
            <br />
            Validator:{" "}
            <a
              href={`${explorerAddress}/staking/${transaction.validatorAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              <abbr title={transaction.validatorAddress}>
                {transaction.validatorAddress.substr(0, 9)}...
                {transaction.validatorAddress.substr(-5)}
              </abbr>
            </a>
            <br />
            Amount:{" "}
            {reduceValue(
              transaction.amount.amount,
              transaction.amount.denom
            )}{" "}
            {transaction.amount.denom.toUpperCase().substr(1)}
          </React.Fragment>
        )
      }
      case "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission": {
        return (
          <React.Fragment>
            Validator:{" "}
            <a
              href={`${explorerAddress}/staking/${transaction.validatorAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              <abbr title={transaction.validatorAddress}>
                {transaction.validatorAddress.substr(0, 9)}...
                {transaction.validatorAddress.substr(-5)}
              </abbr>
            </a>
          </React.Fragment>
        )
      }
    }
    return null
  }

  const downloadCsv = () => {
    const csvData = [
      [
        "Block",
        "Transaction ID",
        "Message type",
        "From",
        "To",
        "Validator",
        "Delegator",
        "Amount",
      ],
      ...transactions.map(transaction => [
        transaction.block,
        transaction.tx,
        transaction.type
          .split(".")
          .pop()
          .substr(3)
          .replace(/([A-Z])/g, " $1")
          .trim(),
        transaction.fromAddress,
        transaction.toAddress,
        transaction.validatorAddress,
        transaction.delegatorAddress,
        transaction.amount
          ? Array.isArray(transaction.amount)
            ? transaction.amount.map(a => `${a.amount}${a.denom}`).join(", ")
            : `${transaction.amount.amount}${transaction.amount.denom}`
          : "",
      ]),
    ]
    exportToCsv("transactions.csv", csvData)
  }

  return (
    <Layout>
      <ChainInfo chain={testChainsJson} data={allFile.edges} testnet />
      <h2>Transactions</h2>
      <h3>Uptick Testnet</h3>
      <div
        className={mainStyles.flexRow}
        style={{ justifyContent: "space-between", alignItems: "flex-end" }}
      >
        <div>
          <label htmlFor="fromBlock">From height</label>
          <input
            id="fromBlock"
            type="number"
            value={fromBlock}
            onChange={e => setFromBlock(parseInt(e.target.value) || "")}
            placeholder="123456"
            style={{ width: "calc(100% - 2rem)" }}
            disabled={isLoading}
          />
        </div>
        <div>
          <label htmlFor="toBlock">To height</label>
          <input
            id="toBlock"
            type="number"
            value={toBlock}
            onChange={e => setToBlock(parseInt(e.target.value) || "")}
            placeholder="123456"
            style={{ width: "calc(100% - 2rem)" }}
            disabled={isLoading}
          />
        </div>
        <button onClick={doLookup} disabled={isLoading}>
          Search
        </button>
      </div>
      {/**/}
      <fieldset>
        <legend>Advanced</legend>
        <label htmlFor="rpcAddress">RPC address</label>
        <input
          id="rpcAddress"
          type="text"
          value={rpcAddress}
          onChange={e => setRpcAddress(e.target.value)}
          placeholder="https://uptick-testnet-3-rpc.brocha.in"
          style={{ width: "calc(100% - 2rem)" }}
          disabled={isLoading}
        />
        <em>
          Note: Changing the RPC address to a different network requires you to
          change the explorer link accordingly. Otherwise the links will not
          work.
        </em>
        <label htmlFor="explorerAddress">Explorer address</label>
        <input
          id="explorerAddress"
          type="text"
          value={explorerAddress}
          onChange={e => setExplorerAddress(e.target.value)}
          placeholder="https://testnet-explorer.brocha.in/uptick%20phase%203"
          style={{ width: "calc(100% - 2rem)" }}
        />
      </fieldset>
      {/**/}
      {statusMessage ? <Code>{statusMessage}</Code> : null}
      {transactions.length ? (
        <React.Fragment>
          <table cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th>Block</th>
                <th>Transaction</th>
                <th>Type</th>
                <th>Information</th>
                {/**}
              <th>Validator</th>
              <th>Delegator</th>
              {/**/}
              </tr>
            </thead>
            <tbody>
              {transactions.length
                ? transactions
                    // .filter(account => !accountAddress || accountAddress === account)
                    .map((transaction, i) => (
                      <tr key={i}>
                        <td>{transaction.block}</td>
                        <td>
                          <a
                            href={`${explorerAddress}/tx/${transaction.tx}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <abbr title={transaction.tx}>
                              {transaction.tx.substr(0, 8)}...
                            </abbr>
                          </a>
                        </td>
                        <td>
                          {transaction.type
                            .split(".")
                            .pop()
                            .substr(3)
                            .replace(/([A-Z])/g, " $1")
                            .trim()}
                        </td>
                        <td>{renderInformation(transaction)}</td>
                        {/**}
                      <td>
                        <a
                          href={`${explorerAddress}/staking/${transaction.validatorAddress}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <abbr title={transaction.validatorAddress}>
                            {transaction.validatorAddress
                              ? `${transaction.validatorAddress.substr(
                                  0,
                                  9
                                )}...${transaction.validatorAddress.substr(-5)}`
                              : null}
                          </abbr>
                        </a>
                      </td>
                      <td>
                        <a
                          href={`${explorerAddress}/account/${transaction.delegatorAddress}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <abbr title={transaction.delegatorAddress}>
                            {transaction.delegatorAddress
                              ? `${transaction.delegatorAddress.substr(
                                  0,
                                  9
                                )}...${transaction.delegatorAddress.substr(-5)}`
                              : null}
                          </abbr>
                        </a>
                      </td>
                      {/**/}
                      </tr>
                    ))
                : null}
              {isLoading ? (
                <tr>
                  <td colSpan={5}>
                    <Spinner /> Loading transactions...
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
          <button onClick={downloadCsv} disabled={isLoading}>
            <BsDownload /> Download results (.csv)
          </button>
        </React.Fragment>
      ) : null}
    </Layout>
  )
}

export default Chain

export const query = graphql`
  query {
    testChainsJson(key: { eq: "uptick" }) {
      id
      about
      explorerUrl
      hidden
      key
      logo
      name
      site
      services {
        tmVersion
        gitRepo
        binary
        root
        publicRpc
        publicGrpc
        publicRest
        seedNode
        chainId
        denom
        snapshot
        installation {
          genesisUrl
          addrbookUrl
          seeds
          installScript
          versions {
            gitTag
            name
          }
        }
        stateSync {
          rpc
          peer
        }
        networkMap
      }
    }
    allFile {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          publicURL
        }
      }
    }
  }
`
